<template>
  <div class="app-container v">
    <div class="head-container">
      <el-select v-model="query.dateType" style="width:190px" class="filter-item" @change="changeDateType">
        <el-option label="考核阶段" value="total">考核阶段</el-option>
        <el-option label="按天" value="day">按天</el-option>
      </el-select>
      <div v-if="query.dateType==='day'">
        <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" range-separator="至" start-placeholder="起始日期" end-placeholder="截止日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toSearch" />
        <el-select v-model="query.isTotal" style="width:90px" class="filter-item" @change="toQuery">
          <el-option label="汇总" :value="true">汇总</el-option>
          <el-option label="明细" :value="false">明细</el-option>
        </el-select>
      </div>
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toSearch">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload" v-if="query.dateType==='day'">导出</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toSearch">刷新</el-button>
    </div>
    <el-card class="box-card" shadow="never" v-if="query.dateType==='day'">
      <div class="c h sb fs-small bold" style="color:#606266">
        <div>累计零售额合计：{{performanceTotal.salePrice/100}}</div>
        <div>累计采购额合计：{{performanceTotal.purchasePrice/100}}</div>
        <div>总部发货数量合计：{{performanceTotal.sendCount}}</div>
        <div>采购入库数量:{{performanceTotal.inCount}}</div>
        <div>累计送货额合计：{{performanceTotal.sendPrice/100}}</div>
        <div>
          零售转单率合计：
          {{(performanceTotal.purchasePrice && performanceTotal.salePrice)?((performanceTotal.purchasePrice /performanceTotal.salePrice )*100).toFixed(2)+"%":"--"}}
        </div>
        <div>
          到货入库率合计：
          {{(performanceTotal.inCount && performanceTotal.sendCount)?((performanceTotal.inCount /performanceTotal.sendCount )*100).toFixed(2)+"%":"--"}}
        </div>
        <div>
          送货及时率合计：
          {{(performanceTotal.sendPrice && performanceTotal.salePrice)?((performanceTotal.sendPrice /performanceTotal.salePrice )*100).toFixed(2)+"%":"--"}}
        </div>
      </div>
    </el-card>
    <el-table v-loading="loading" :data="data" :default-sort="{prop: 'dateId', order: 'descending'}" @sort-change="handleSort" size="small" height="200">
      <el-table-column type="index" fixed />
      <el-table-column prop="erpId" label="经销商编码" width="120" :show-overflow-tooltip="true" sortable="custom" />
      <el-table-column prop="disEntName" label="经销商名称" min-width="240" :show-overflow-tooltip="true" sortable="custom" />      
      <!-- <el-table-column prop="provinceName" label="省份" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{scope.row.provinceName || "-"}}</template>
      </el-table-column>
      <el-table-column prop="cityName" label="城市" :show-overflow-tooltip="true">
        <template slot-scope="scope">{{scope.row.cityName || "-"}}</template>
      </el-table-column>
      <el-table-column prop="areaName" label="区域" :show-overflow-tooltip="true" align="center" sortable="custom">
        <template slot-scope="scope">{{scope.row.areaName || "-"}}</template>
      </el-table-column> -->
      <el-table-column prop="year" label="年度" width="80" sortable="custom" v-if="this.query.dateType==='total'"/>
      <el-table-column prop="dateName" label="考核季度" width="120" sortable="custom" v-if="this.query.dateType==='total'"/>
      <el-table-column prop="day" label="日期" width="120px" :formatter="v=>{return v.day?new Date(v.day).format('yyyy/MM/dd'):''}" sortable="custom" v-if="query.dateType==='day' && !query.isTotal" />
      <el-table-column prop="salePrice" label="累计零售额" width="110" :formatter="$price" align="center" sortable="custom" />
      <el-table-column prop="purchasePrice" label="累计采购额" width="110" :formatter="$price" align="center" sortable="custom" />
      <el-table-column label="零售转单率" width="110" align="center" sortable="custom">
        <template slot-scope="scope">
          <span v-if="scope.row.purchasePrice &&scope.row.salePrice">{{((scope.row.purchasePrice/scope.row.salePrice)*100).toFixed(0)}}%</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="inCount" label="采购入库数量" align="center" width="120" sortable="custom">
        <template slot-scope="scope">{{scope.row.inCount || "-"}}</template>
      </el-table-column>
      <el-table-column prop="sendCount" label="总部发货数量" align="center" width="120" sortable="custom">
        <template slot-scope="scope">{{scope.row.sendCount || "-"}}</template>
      </el-table-column>
      <el-table-column label="到货入库率" width="110" align="center" sortable="custom">
        <template slot-scope="scope">
          <span v-if="scope.row.inCount &&scope.row.sendCount">{{((scope.row.inCount/scope.row.sendCount)*100).toFixed(0)}}%</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column prop="sendPrice" label="累计送货额" width="110" :formatter="$price" align="center" sortable="custom" />
      <el-table-column label="送货及时率" width="110" align="center" sortable="custom" fixed="right">
        <template slot-scope="scope">
          <span v-if="scope.row.sendPrice &&scope.row.salePrice">{{((scope.row.sendPrice/scope.row.salePrice)*100).toFixed(0)}}%</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>
<script>
import checkPermission from "@/utils/permission";
import { parseTime } from "@/utils/index";
import initData from "@/mixins/initData";
import { mapGetters } from "vuex";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
import request from "@/utils/request";
export default {
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      downloadLoading: false,
      loading: false,

      sort: "erpId",
      performanceTotal: {},
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近两年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 2);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      query: {
        dateType:"total",
        isTotal: true,
        isDistributor: true,
        dateRange: [],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
    this.$nextTick(() => {
      this.init();
      this.loadTotal();
    });
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = "api/performanceData/day";
      if(this.query.dateType==='total'){
        this.url = "api/performanceData";
      }
      this.params = {
        page: this.page,
        size: this.size,
        sort: this.sort,
      };
      Object.assign(this.params, this.query);
      const query = this.query;
      if (query.dateRange && query.dateRange.length === 2) {
        this.params.beg = new Date(query.dateRange[0]).format(
          "yyyy-MM-dd hh:mm:ss"
        );
        this.params.end = new Date(query.dateRange[1]).format(
          "yyyy-MM-dd hh:mm:ss"
        );
      } else {
        delete this.params.beg;
        delete this.params.end;
      }
      return true;
    },
    changeDateType(){
      if(this.query.dateType==='total'){
        this.sort = "dateId";
      }else{
        this.sort = "erpId";
      }
      this.toQuery();
    },
    afterLoad(ds) {
      ds.forEach((d) => (d._enabled = !d.disabled));
    },
    changeStatus(val, row) {
      let data = { disEntId: row.disEntId, disabled: !val };
      request({
        url: "api/performanceState/change",
        method: "put",
        data,
      })
        .then((res) => {
          this.$notify({
            title: "修改状态成功",
            type: "success",
            duration: 2500,
          });
        })
        .finally((_) => {
          this.init();
        });
    },
    handleSort(sort) {
      if (sort.order == null) {
        this.sort = "";
      } else if (sort.column.label == "零售转单率") {
        this.sort =
          "saleRatio," + (sort.order === "ascending" ? "asc" : "desc");
      } else if (sort.column.label == "到货入库率") {
        this.sort =
          "inStoreRatio," + (sort.order === "ascending" ? "asc" : "desc");
      } else if (sort.column.label == "送货及时率") {
        this.sort =
          "sendRatio," + (sort.order === "ascending" ? "asc" : "desc");
      } else if (sort.column.label == "当前限购状态") {
        this.sort = "disabled," + (sort.order === "ascending" ? "asc" : "desc");
      } else {
        this.sort =
          sort.prop + "," + (sort.order === "ascending" ? "asc" : "desc");
      }
      this.toQuery();
    },
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      let params = Object.assign(
        { page: 0, size: this.total, sort: this.sort },
        this.query
      );
      download("api/performanceData/day/export", params)
        .then((result) => {
          downloadFile(result, "考核分析", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    loadTotal() {
      if (this.query.dateRange && this.query.dateRange.length === 2) {
        this.query.beg = new Date(this.query.dateRange[0]).format(
          "yyyy-MM-dd hh:mm:ss"
        );
        this.query.end = new Date(this.query.dateRange[1]).format(
          "yyyy-MM-dd hh:mm:ss"
        );
      } else {
        delete this.query.beg;
        delete this.query.end;
      }

      request({
        url: "/api/performanceData/day/total",
        method: "get",
        params: this.query,
      }).then((res) => {
        this.performanceTotal = res;
      });
    },
    toSearch() {
      this.toQuery();
      this.loadTotal();
    },
  },
};
</script>